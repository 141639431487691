<template>
  <div class="container">


    <div class="articles-formation-requirements mt-4 mb-4">
      <h4 class="text-center text-uppercase mb-4">ТРЕБОВАНИЯ К ОФОРМЛЕНИЮ СТАТЕЙ НА МЕЖДУНАРОДНУЮ НАУЧНУЮ КОНФЕРЕНЦИЮ
        <br>
        «САГАДИЕВСКИЕ ЧТЕНИЯ 2022 – НОВАЯ ЭКОНОМИЧЕСКАЯ ПОЛИТИКА РК: РЕАЛИИ И ПЕРСПЕКТИВ»</h4>


      <div class="row mt-4">
        <p class="text-center">
          <b>Структура статьи и критерии рецензирования:</b>
        </p>
        <p>Оргкомитет конференции нацелен на соответствие высоким международным стандартам, в связи с чем статьи должны отражать результаты оригинальных теоретических, методических и прикладных научных исследований.</p>
        <p>
          Приводим основные общепринятые элементы научной статьи, которые являются основными критериями для ее оценивания при прохождении двойного анонимного рецензирования: <b>введение, цель и задачи исследования, обзор литературы, методика исследования, полученные результаты и их обсуждение, вклад результатов исследования в науку, бизнес или общество, выводы.</b>
        </p>
      </div>

      <div class="row mt-4">
        <p class="text-center"><b>Технические требования к статье.</b></p>
        <ul>
          <li>Предоставляемые статьи должны являться оригинальными неопубликованными ранее в других печатных или электронных изданиях.</li>
          <li>Загружаемый в систему файл со статьей должен быть представлен в формате Microsoft Word (иметь расширение *.doc, *.docx).</li>
          <li>Статья может быть на казахском, русском или английском языке.</li>
          <li>
            Название статьи: прописными буквами полужирным шрифтом, выравнивание по центру.
            <br>
            <b>Примечание:</b> ФИО и аффилиации автора (-ов) не указывать в тексте статьи. Эта информация об авторе (-ах) фиксируется при регистрации.
          </li>
          <li>Объем статьи – 3500 и более слов, включая название статьи, абстракт (250-300 слов), ключевые слова (5-7 слов), текст статьи и список литературы.</li>
          <li>Шрифт – TNR 12; интервал 1,5; абзацный отступ – 0,75. Поля: верхнее и нижнее – 20 мм, левое – 30 мм, правое – 15 мм.</li>
          <li>В тексте статьи ссылки оформляются по правилам ГОСТ (если статьи на русском и казахском языках) или APA -8 style (если статьи на английском языке).</li>
          <li>
            Список литературы (не менее 20) помещается в конце текста и отделяется пустой строкой. Источники в списке указываются по мере цитирования в основном тексте (по ГОСТу) и в алфавитном порядке (по APA).
            <br>
            <b>Примечание:</b> список литературы загружается в систему отдельным файлом (не в тексте статьи)
          </li>
          <li>Номер и название рисунка размещают под рисунком, выравнивание – по центру. К рисункам относятся также схемы, фотографии, сканированные изображения и т.д. </li>
          <li>Номер и название таблицы указываются над таблицами (выравнивание – по центру).</li>
          <li>Таблицы, схемы, рисунки, формулы, графики не должны выходить за пределы указанных полей. Шрифт в таблицах и на рисунках – 10 pt.</li>
          <li>Кавычки обозначаются знаком «».</li>
          <li>При указании дат: Век обозначается римскими цифрами, Годы – арабскими.</li>
          <li>Не допускаются ручные переносы (manual hyphenation).</li>
        </ul>

      </div>

      <div class="row mt-4">
        <p class="text-center"><b>Пример оформления списка использованных источников по ГОСТ</b></p>
        <ol>
          <li>Агафонова Н.Н. Гражданское право: учебное пособие для вузов / под ред. А.Г. Калпина. – Изд. 2-е, перер. и доп. – М.: Юрист, 2003. – 542 с.</li>
          <li>Портер М.Е. Международная конкуренция / пер. с англ.; под ред. В.Д. Щепина. – М.: Международные отношения, 1993. – 140 с.</li>
          <li>Павлов Б.П. Батуев С.П. Подготовка водомазутных эмульсий для сжигания в топочных устройствах // В кн.: Повышение эффективности использования газообразного и жидкого топлива в печах и отопительных котлах. – Л.: Недра, 1983. – 216 с.</li>
          <li>Пат. 5773 РК. Способ извлечения золота из синтетических ионитов /Г.А. Нечистых; опубл. 30.03.98, Бюл. № 6.- 4 с.</li>
          <li>Vachaspati Т., Vidеnkin A. Evolution of cosmic nets //Phys.Rev.-2003.- Vol.46, №2. -P.1133-1140.</li>
          <li>Указ Президента Республики Казахстан. О некоторых вопросах развития агропромышленного комплекса: утв. 11 декабря 2006 года, № 220.</li>
          <li>Стратегический план развития Республики Казахстан до 2010 года: утв. Указом Президента Республики Казахстан от 4 декабря 2001 года, № 735 //
            <a href="www.minplan.kz">www.minplan.kz</a>. 28.12.2001.</li>
          <li>Байтова А. Инновационно-технологическое развитие – ключевой фактор повышения конкурентоспособности // Казахстанская правда. – 2009. – № 269.</li>
          <li>Кузьмин Н. Универсальный солдат. «Эксперт Online» <a href="http://www.nomad.su">http://www.nomad.su</a> 13.10.2009.</li>
          <li>ГОСТ Р 51771 - 2001. Аппаратура радиоэлектронная бытовая. Входные и выходные параметры и типы соединений. Технические требования. - Введ. 2002-01-01. - М.: Госстандарт Россия: Изд-во стандартов, 2001. – IV.- 27 с.</li>
        </ol>
      </div>

      <div class="row mt-4">
        <p class="text-center">
          <b>Пример оформления списка использованных источников в стиле APA 8</b>
        </p>


        <!-- table start -->
        <div class="table-responsive-md mt-4">
          <table class="table table-striped table-bordered">
            <tbody>

            <tr>
              <td><b>Book, Single Author</b></td>
              <td>Ball, P. (2001). <i>Bright earth: Art and the invention of color.</i> New York: Farrar, Straus and Giroux.</td>
            </tr>

            <tr>
              <td><b>Book, Multiple Authors</b></td>
              <td>Bird, K., & Martin, J. S. (2005). <i>American prometheus: The triumph and tragedy of J. Robert Oppenheimer.</i> New York: Alfred A. Knopf.</td>
            </tr>

            <tr>
              <td><b>Book, Editor</b></td>
              <td>Silverstein, T. (Ed). (1974). <i>Sir Gawain and the green knight.</i> Chicago: University of Chicago Press.</td>
            </tr>

            <tr>
              <td><b>Chapter in a Book</b></td>
              <td>Demos, J. (2001). Real lives and other fictions: Reconsidering Wallace Stegner's <i>Angle of Repose.</i> In Carnes, M. (Ed.), <i>Novel history: Historians and novelists confront America's past (and each other), (pp. 132-145).</i> New York: Simon and Schuster.</td>
            </tr>


            <tr>
              <td><b>Journal Article</b></td>
              <td>Burns, S. (2005). Ordering the artist's body: Thomas Eakins' acts of self-portrayal. <i>American Art</i>, 19(1), 90-102.</td>
            </tr>

            <tr>
              <td><b>Journal Article with DOI</b></td>
              <td>Murdock, L., & Hobbs, J. (2011, July). Picture me playing: Increasing pretend play dialogue of children with autism spectrum disorders. <i>Journal of Autism and Developmental Disorders</i>, 41(7), 870-878. doi: 10.1007/s10803-010-1108-6</td>
            </tr>

            <tr>
              <td><b>Journal Article without a DOI</b></td>
              <td>Tilak, J.G. (2002). Education and poverty. <i>Journal of Human Development</i>, 3(2), 191-207. Retrieved from <a href="http://www.tandfonline.com/loi/cjhd20">http://www.tandfonline.com/loi/cjhd20</a></td>
            </tr>

            <tr>
              <td><b>Thesis or Dissertation</b></td>
              <td>Erickson, C. (2008). <i>Critical multiculturalism and preservice teacher education</i> (Doctoral dissertation, University of West Florida). Retrieved from <a href="http://purl.fcla.edu/fcla/etd/WFE000011">http://purl.fcla.edu/fcla/etd/WFE000011</a></td>
            </tr>

            <tr>
              <td><b>Website (nonperiodical web document)</b></td>
              <td>Florida Department of Education. (2010). <i>Next generation sunshine state standards: Grade two, social studies.</i> Retrieved from <a href="http://www.floridastandards.org/Standards/FLStandardSearch.aspx">http://www.floridastandards.org/Standards/FLStandardSearch.aspx</a></td>
            </tr>
            </tbody>
          </table>

        </div>
        <!-- table end -->
      </div>






    </div>

  </div>


</template>

<script>

export default {
  name: "ArticlesFormationRequirements",
  data() {
    return {

    }
  },
  computed: {

  },

  methods: {

  },

  async mounted() {

  }
}
</script>

<style scoped>

</style>